import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallback: "en",
    resources: {
      ar: {
        translations: require("./locales/ar/translations.json"),
      },
      de: {
        translations: require("./locales/de/translations.json"),
      },
      el: {
        translations: require("./locales/el/translations.json"),
      },
      en: {
        translations: require("./locales/en/translations.json"),
      },
      es: {
        translations: require("./locales/es/translations.json"),
      },
      fr: {
        translations: require("./locales/fr/translations.json"),
      },
      hi: {
        translations: require("./locales/hi/translations.json"),
      },
      it: {
        translations: require("./locales/it/translations.json"),
      },
      ja: {
        translations: require("./locales/ja/translations.json"),
      },
      nl: {
        translations: require("./locales/nl/translations.json"),
      },
      pt: {
        translations: require("./locales/pt/translations.json"),
      },
      ru: {
        translations: require("./locales/ru/translations.json"),
      },
      sv: {
        translations: require("./locales/sv/translations.json"),
      },
      tr: {
        translations: require("./locales/tr/translations.json"),
      },
      zh: {
        translations: require("./locales/zh/translations.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });
