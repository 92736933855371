import { useEffect, useCallback } from "react";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@fontsource/rubik";
import "@fontsource/rubik/700.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

const Home = lazy(() => import("./components/Home"));
const Privacy = lazy(() => import("./components/Privacy"));
const Faq = lazy(() => import("./components/Faq"));

const GTM_ID = process.env.REACT_APP_GTM_ID || "GTM-55V6969W";

const useGoogleTagManager = (consent) => {
  const addInlineScriptToHead = useCallback((scriptContent) => {
    const script = document.createElement("script");
    script.innerHTML = scriptContent;
    document.head.appendChild(script);
  }, []);

  const addGTMIframe = useCallback(() => {
    const noscriptTag = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscriptTag.appendChild(iframe);
    document.body.appendChild(noscriptTag);
  }, []);

  const addGoogleAdsense = useCallback(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8721099372263955";
    script.crossOrigin = "anonymous";
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production" && consent === "accepted") {
      const gtmScriptContent = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');`;
      addInlineScriptToHead(gtmScriptContent);
      addGTMIframe();
      addGoogleAdsense();
    }
  }, [consent, addInlineScriptToHead, addGTMIframe, addGoogleAdsense]);
};

function App() {
  const { t } = useTranslation();
  const consent = localStorage.getItem("cookieConsent");
  useGoogleTagManager(consent);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    window.location.reload(); // Reload to apply GTM script
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
  };

  return (
    <MantineProvider>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/faq" element={<Faq />} />
          </Routes>
        </Suspense>
        <CookieConsent
          enableDeclineButton
          location="bottom"
          buttonText={t("cookie-notice-accept-button-text")}
          buttonStyle={{
            backgroundColor: "var(--mantine-color-blue-7)",
            color: "white",
            borderRadius: "var(--mantine-radius-sm)",
          }}
          declineButtonText={t("cookie-notice-decline-button-text")}
          declineButtonStyle={{
            backgroundColor: "var(--mantine-color-blue-1)",
            color: "var(--mantine-color-grey-7)",
            borderRadius: "var(--mantine-radius-sm)",
          }}
          onAccept={handleAccept}
          onDecline={handleDecline}
          cookieName="qrccnotice"
          style={{
            fontFamily: "Rubik, sans-serif",
            backgroundColor: "var(--mantine-color-blue-0)",
            color: "var(--mantine-color-grey-7)",
          }}
          expires={150}
          overlay={true}
        >
          <Trans
            i18nKey="cookie-notice"
            components={{ 1: <Link to="/privacy" /> }}
          />
        </CookieConsent>
      </Router>
    </MantineProvider>
  );
}

export default App;
